@media (max-width: 991px) {
	
	#header .menu-icon{
        display:inline-block;
    }
    #header ul li.logo {
        width: auto;
    }
    #header .sideBar{
        top:46px;
    }
    #header .sideBar .btn {
        margin: 52px 20px 10px 20px;
    }
    #header .sideBar .logo{
        display:none;
    }
    #material .card.side .header{
        padding:1rem 0;
    }
    #vs-list #chat .add-images-modal .images-list {
        padding: 1.5rem 0;
    }
    .whole-container{
        padding: 75px 30px 30px 30px;
    }
    #video,
    #forum,
    #room,
    #material,
    #vs-list{
        padding: 45px 0px 0px 0px;
    }
    #vs-list{
        height: auto;
    }
    #vs-list .vs-content .card.is-group {
        height: auto;
    }
    #no-membership  {
        padding: 80px 15px 40px 15px;
    }
    #header .sideBar {
        transform: translateX(-300px);
    }
    #header .side-bar-overlay{
        display:block;
    }
    #vs-list .group-header h3{
        font-size:1.8rem;
    }

    #material .material-content iframe,
    #vs-list .vs-content iframe{
        height: calc((100vw - 410px) * 9 / 16);
    }
    #material h2{
        font-size: 2.5rem;
        line-height: 3.5rem;
    }

    #vs-list #chat {
        left:20px;
    }

    #vs-list #chat .all-messages .current-message-content,
    #vs-list #chat .write-message .message-container .message,
    #vs-list #chat .all-messages .current-message-content *{
        font-size:0.875rem;
    }

    .row.m-40{
        margin-right: -15px;
        margin-left: -15px;
    }
    .service{
        display:flex;
        flex-direction: column;
        padding-right: 0px;
        padding-left: 0px;
    }
    .service .card {
        margin-bottom: 3rem;
    }

    #no-internet {
        padding: 0px 0px 0px 0px;
    }

}