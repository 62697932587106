.theme-dark{
    background-image: url("../Images/bg_dark.webp");
    color:var(--theme-dark-color);
    background-color: var(--theme-very-dark-bg);
}
.theme-dark #header{
    background-image: url("../Images/header_bg_dark.webp");
}
.theme-dark  a:not(.btn){
	color: var(--color-semi-gold);
}
.theme-dark #header .sideBar{
    background-color: var(--theme-dark-bg);
}

.theme-dark #header .sideBar ul li a, 
.theme-dark #header .sideBar ul li button, 
.theme-dark #header .sideBar ul li span{
    color:var(--theme-dark-color);
}
.theme-dark #header .sideBar ul li {
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark #header .sideBar ul li a.active, 
.theme-dark #header .sideBar ul li ul li.activated a:hover {
    background: radial-gradient(50% 100% at 50% 100%, #4c4a42 0%, rgba(255, 255, 255, 0) 100%);
    color: var(--color-semi-gold);
}
.theme-dark #header .sideBar ul li a:hover {
    background: radial-gradient(50% 100% at 50% 100%, #4c4a42 0%, rgba(255, 255, 255, 0) 100%);
}
.theme-dark #header ul li.membership span {
    color: var(--color-semi-gold);
}
.theme-dark #header .app-version{
	color:#6b6b6b;
}
.theme-dark .card {
    background-color: var(--theme-dark-bg) !important;
}
.theme-dark .card .card-header select, 
.theme-dark .card .card-header .form-control:focus, 
.theme-dark .card .card-header .form-control:active {
    border: 1px solid #525252;
    background-color: #6D6D6D;
}
.theme-dark .card .card-header, 
.theme-dark .modal-header{
    background: linear-gradient(269.27deg, #595959 0%, #393939 50.56%);
    color:var(--theme-dark-light-color);
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark #header .notifications-list{
    background-color: var(--theme-very-dark-bg);
    border-left:1px solid var(--theme-dark-border);
    border-right:1px solid var(--theme-dark-border);
    border-bottom:1px solid var(--theme-dark-border);
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.75);
}
.theme-dark #header .notifications-list ul li:hover {
    background: radial-gradient(50% 100% at 50% 100%, #4c4a42 0%, rgba(255, 255, 255, 0) 100%);
}
.theme-dark #header .notifications-list-footer {
    border-top: 1px solid var(--theme-dark-border);
}
.theme-dark #header .notifications-list li{
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark #no-membership {
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark .table {
    color:var(--theme-dark-color) !important;
}

.theme-dark .table thead th {
    border-bottom: 4px solid var(--theme-dark-border) !important;
}
.theme-dark .table td, 
.theme-dark .table th {
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark .table tr.total td,
.theme-dark .data-list-item.footer {
    border-top: 4px solid var(--theme-dark-border);
    background-color: #2e2e2e;
}

.theme-dark .paid-off {
    border: 1px solid var(--theme-dark-border)
}

.theme-dark #ls-gift .col-12:nth-child(2) {
    border-left: 1px solid var(--theme-dark-border);
}
.theme-dark .service .icon{
    background-color: var(--theme-dark-bg);
}

.theme-dark textarea.form-control, 
.theme-dark input.form-control, 
.theme-dark select.form-control, 
.theme-dark select.form-control:focus, 
.theme-dark select.form-control:active, 
.theme-dark input.form-control:focus, 
.theme-dark input.form-control:active, 
.theme-dark .input-group-text, 
.theme-dark .form-control:disabled, 
.theme-dark .form-control[readonly],
.theme-dark .custom-file-label {
    background-color: var(--theme-dark-input);
    color: var(--theme-dark-color);
}

.theme-dark .custom-file-label {
    border: 1px solid var(--theme-dark-border);
}
.theme-dark .custom-file-input:focus~.custom-file-label {
    border-color: var(--theme-dark-border);
    box-shadow: none;
}

.theme-dark .modal-content {
    background-color: var(--theme-dark-bg);
}
.theme-dark  .modal-footer {
    border-top: 1px solid var(--theme-dark-border);
}

.theme-dark .daily-posts .post .header {
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark .daily-posts .post .header * {
    color:  var(--theme-dark-light-color);
}
.theme-dark .daily-posts .post .from {
    color: var(--color-semi-gold);
}
.theme-dark .nav-tabs .nav-link{
    background-color: var(--theme-dark-bg);
    border: 1px solid var(--theme-dark-bg);
    color:#ddd;
}
.theme-dark .nav-tabs .nav-link.active{
    background: radial-gradient(50% 100% at 50% 100%, #4c4a42 0%, rgba(255, 255, 255, 0) 100%);
    color: var(--color-semi-gold);
    border: 1px solid var(--color-semi-gold);
}
.theme-dark #material .mobile-lessons{
    background-color: var(--theme-dark-bg);
}
.theme-dark #material .card.side ul li.selected,
.theme-dark #material .card.side .header.selected{
    background: radial-gradient(50% 100% at 50% 100%, #4c4a42 0%, rgba(255, 255, 255, 0) 100%);
    color: var(--color-semi-gold);
}
.theme-dark #material .card.side ul li a{
	color:var(--theme-dark-color);
}
.theme-dark #material .card.side ul li a:hover, 
.theme-dark #material .card.side ul li.selected a {
    color: var(--color-semi-gold);
}

.theme-dark  #material .card.side .header,
.theme-dark  #material .card.side ul li {
    border-bottom: 1px solid var(--theme-dark-border);
}

.theme-dark  #material .card.side .header:hover h3, 
.theme-dark  #material .card.side .header.selected h3 {
    color: var(--color-semi-gold);
}

.theme-dark #ls-gift .tabs .tab-item{
    background-color: var(--theme-dark-bg);
}
.theme-dark #my-vs .tabs .tab-item,
.theme-dark #my-vs .tabs:after {
    background-color: var(--theme-very-dark-bg);
}
.theme-dark #ls-gift .tabs:after{
    background-color: var(--theme-dark-bg);
}
.theme-dark .daily-posts .mobile-tabs {
    background-color: var(--theme-dark-bg);
    color: var(--color-semi-gold);
}

.theme-dark .calendar-events .card-body .date {
    color: var(--color-dark);
}

.theme-dark .profile-avatar .name-container,
.theme-dark .profile-avatar .sub-info {
    background-color: var(--theme-dark-bg);
}

.theme-dark hr {
    border-top: 4px solid var(--theme-dark-border);
}

.theme-dark .avatar-image img {
    border: 5px solid var(--color-semi-gold)
}

.theme-dark .avatar-image.in-row img {
    border: 2px solid var(--color-semi-gold)
}
.theme-dark .profile-avatar.fluid .name-container p.main-name,
.theme-dark .profile-avatar-row .main-name {
    color:#ddd;
}
.theme-dark #vs-list .vs-list .vs{
    color: var(--theme-dark-color);
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark #vs-list .vs-list .vs.group-container:not(.no-clicable) .main-name:hover, 
.theme-dark #vs-list .vs-list .vs:hover:not(.group-container) .main-name, 
.theme-dark #vs-list .vs-list .vs.selected .main-name {
    color: var(--color-semi-gold);
}
.theme-dark #vs-list .vs-list .vs.selected {
    background: radial-gradient(50% 100% at 50% 100%, #4c4a42 0%, rgba(255, 255, 255, 0) 100%);
}
.theme-dark #vs-list #chat .all-messages .current-message .message-options .option:hover {
    background: radial-gradient(50% 100% at 50% 100%, #4c4a42 0%, rgba(255, 255, 255, 0) 100%);
}
.theme-dark #vs-list #chat .all-messages .current-message.vs .current-message-content,
.theme-dark #vs-list #chat .all-messages .current-message.vs .current-message-container  {
    background-color: var(--theme-dark-bg);
}
.theme-dark #vs-list #chat .all-messages .current-message-date {
    border-top: 1px solid var(--theme-dark-border);
}
.theme-dark #vs-list #chat .all-messages .current-message .image {
    background-color: var(--color-semi-gold);
    border: 1px solid var(--color-semi-gold);
}
.theme-dark #vs-list #chat .all-messages .current-message.me .current-message-content,
.theme-dark #vs-list #chat .all-messages .current-message.me .current-message-container{
    background-color: #584f35;
    color: var(--color-semi-gold-dark-theme);
}
.theme-dark #vs-list #chat .all-messages .current-message .current-message-content p a {
    color: var(--color-semi-gold-dark-theme);
}
.theme-dark #vs-list #chat .all-messages .current-message.me .current-message-content p a {
    color: #d1d0c7;
}
.theme-dark #vs-list .group-header,
.theme-dark .modal-body .nav-tabs.with-border, 
.theme-dark #vs-list .nav-tabs.with-border,
.theme-dark .data-list-item.header {
    border-bottom: 4px solid var(--theme-dark-border);
}
.theme-dark .data-list-item-content {
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark #vs-list #chat .write-message .message,
.theme-dark #vs-list #chat .write-message .emotikons .emotikons-container,
.theme-dark #vs-list #chat .write-message .send .send-container,
.theme-dark #vs-list #chat .write-message .add-images-container {
    background-color: var(--theme-dark-input);
}
.theme-dark #vs-list #chat .all-messages .current-message .remove-message.my {
    color: var(--color-gold-text-dark-theme);
}
.theme-dark #vs-list #chat .all-messages .current-message .remove-message {
    color: #6b6b6b;
}
.theme-dark .res-blocks .block {
    border-top: 1px solid var(--theme-dark-border);
    border-right: 1px solid var(--theme-dark-border);
    border-left: 1px solid var(--theme-dark-border);
}
.theme-dark .res-blocks .block:last-child {
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark .res-blocks.room .block {
    cursor: default;
    border: 0px none;
    height: auto;
    min-height: 2rem;
    padding: 1rem 0;
}
.theme-dark .clickable-panel {
    background-color:var(--theme-dark-input);
    color: #ddd;
    border: 1px solid var(--theme-dark-input);
}
.theme-dark .clickable-panel.selected {
    background: radial-gradient(50% 100% at 50% 100%, #4c4a42 0%, rgba(255, 255, 255, 0) 100%);
    color: var(--color-semi-gold);
    border: 1px solid var(--color-semi-gold);
}
.theme-dark .emoji-mart{
    background-color: var(--theme-dark-bg); 
    border: 1px solid var(--theme-dark-border);
    color:var(--theme-dark-color);
}
.theme-dark .emoji-mart-bar {
    border: 0 solid var(--theme-dark-border);
}
.theme-dark .emoji-mart-category-label span,
.theme-dark .emoji-mart-category .emoji-mart-emoji:hover:before{
    background-color: var(--theme-dark-bg); 
}
.theme-dark .emoji-mart-search input {
    background-color: var(--theme-dark-input); 
    border: none;
}

.theme-dark .tiny-content h1, 
.theme-dark .tiny-content h2, 
.theme-dark .tiny-content h3, 
.theme-dark .tiny-content h4 {
    color:  var(--theme-dark-light-color);
}

.theme-dark .tox .tox-menubar,
.theme-dark .tox .tox-dialog__header,
.theme-dark .tox .tox-listboxfield .tox-listbox--select, 
.theme-dark .tox .tox-textarea, .tox .tox-textfield, 
.theme-dark .tox .tox-toolbar-textfield {
    background-color: var(--theme-dark-bg);
}
.theme-dark .tox-tinymce{
    border: 1px solid var(--theme-dark-border);
}
.theme-dark .tox:not(.tox-tinymce-inline) .tox-editor-header{
    background-color: var(--theme-dark-bg) !important; 
}
.theme-dark .tox .tox-toolbar, 
.theme-dark .tox .tox-toolbar__overflow, 
.theme-dark .tox .tox-toolbar__primary,
.theme-dark .tox .tox-toolbar-overlord,
.theme-dark .tox .tox-sidebar {
    background-color: var(--theme-dark-bg) !important; 
    background: none;
    border-bottom: 1px solid var(--theme-dark-border);
}
.theme-dark .tox .tox-menubar+.tox-toolbar, 
.theme-dark .tox .tox-menubar+.tox-toolbar-overlord .tox-toolbar__primary {
    border-top: 1px solid var(--theme-dark-border);
}
.theme-dark .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid var(--theme-dark-border);
}
.theme-dark .tox .tox-statusbar,
.theme-dark .tox .tox-menu,
.theme-dark .tox .tox-dialog__footer  {
    background-color: var(--theme-dark-bg); 
    border-top: 1px solid var(--theme-dark-border);
}
.theme-dark .tox .tox-collection--list .tox-collection__item--active {
    background-color: var(--theme-dark-input);
}
.theme-dark .tox .tox-collection--list .tox-collection__item--enabled {
    background-color: var(--theme-dark-input);
}
.theme-dark .tox .tox-collection--list .tox-collection__group,
.theme-dark .tox .tox-menu{
    border-color: var(--theme-dark-border);
}
.theme-dark .tox .tox-dialog{
    border-color: var(--theme-dark-border);
    background-color: var(--theme-dark-bg); 
}

.theme-dark .tox .tox-listboxfield .tox-listbox--select:focus, 
.theme-dark .tox .tox-textarea:focus, .tox .tox-textfield:focus{
    background-color: var(--theme-dark-bg); 
    border-color: var(--theme-dark-border);
}
.theme-dark .tox .tox-button:active:not(:disabled),
.theme-dark .tox .tox-button,
.theme-dark .tox .tox-button:hover:not(:disabled) {
    background-color: transparent;
    border-color: transparent;
    background: linear-gradient(180deg, #FFEEB4 14.06%, #E7C054 100%);
	color:var(--color-dark);
}

.theme-dark .tox .tox-button--secondary,
.theme-dark .tox .tox-button--secondary:hover:not(:disabled){
    background: linear-gradient(180deg, #e2e2e2 14.06%, #797979 100%);
}
.theme-dark .tox .tox-listboxfield .tox-listbox--select, 
.theme-dark .tox .tox-textarea, 
.theme-dark .tox .tox-textfield, 
.theme-dark .tox .tox-toolbar-textfield,
.theme-dark .tox .tox-listboxfield .tox-listbox--select:focus, 
.theme-dark .tox .tox-textarea:focus, 
.theme-dark .tox .tox-textfield:focus{
    background-color: var(--theme-dark-input);
    border-color: transparent
}
.theme-dark .tox .tox-dialog__body-nav-item--active {
    border-bottom: 2px solid var(--color-semi-gold);
    color: var(--color-semi-gold);
}

.theme-dark .tox .tox-dialog-wrap__backdrop{
    background-color: rgba(0,0,0,.55);
}

.theme-dark .tox .tox-collection__item-label h1,
.theme-dark .tox .tox-collection__item-label h2,
.theme-dark .tox .tox-collection__item-label h3,
.theme-dark .tox .tox-collection__item-label h4,
.theme-dark .tox .tox-collection__item-label h5,
.theme-dark .tox .tox-collection__item-label h6,
.theme-dark .tox .tox-collection__item-label pre,
.theme-dark .tox .tox-collection__item-label p,
.theme-dark .tox .tox-collection__item-label blockquote,
.theme-dark .tox .tox-collection__item-label strong,
.theme-dark .tox .tox-collection__item-label em,
.theme-dark .tox .tox-collection__item-label span,
.theme-dark .tox .tox-collection__item-label sub,
.theme-dark .tox .tox-collection__item-label sup,
.theme-dark .tox .tox-collection__item-label div{
    background-color: var(--theme-dark-bg) !important; 
}

.theme-dark .tox .tox-tbtn,
.theme-dark .tox .tox-mbtn{
    background-color: var(--theme-dark-bg) !important; 
}












@media (max-width: 767px) {

    .theme-dark .daily-posts .nav-tabs .nav-link {
        border-bottom: 1px solid var(--theme-dark-border);
    }
    .theme-dark .daily-posts .nav-tabs .nav-link.active {
        border: 0px none;
        background-color: var(--theme-dark-bg);
    }
    .theme-dark .daily-posts .nav-link.active {
        border-bottom: 1px solid var(--theme-dark-border) !important;
    }

}