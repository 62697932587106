@media (max-width: 1500px) {

    #ls-gift .col-12 {
        padding: 0 3rem;
    }
    #ls-gift .row {
        margin: 0 -3rem;
    }
    #ls-gift h2 {
        font-size: 5rem;
        line-height: 6.2rem;
        margin: 0 0 2rem 0;
    }
    #vs-list .groups {
        max-height: 170px;
    }
    #vs-list .vs-content.info .tiny-content {
        width: 100%;
    }
    #vs-list .vs-content.info .tiny-content img{
        height:180px;
    }
    #vs-list .vs-content {
        flex: 1 1;
        padding: 20px 320px 20px 20px;
    }
    #vs-list #chat{
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 20px;
        top: 140px;
        left: 270px;
        right: 320px;
    
    }
    #vs-list #chat.is-group{
        top: 140px;
    }
    #vs-list .vs-tabs .nav-link {
        padding: 1rem 1.5rem;
    }
    #vs-list .vs-tabs .btn-item a{
        font-size:1.3rem !important;	
        max-width: 14rem;
        padding: 0.8rem !important;
    }
    #vs-list #chat .all-messages .current-message .current-message-content *,
    #vs-list #chat .all-messages .current-message.me .current-message-content {
        font-size: 1.4rem;
    }
    #vs-list #chat .all-messages .current-message-content p {
        margin-bottom: 0px;
    }
    #vs-list #chat .add-images-modal .images-list {
        height: 120px;
    }
    #vs-list .vs-content .card.is-group{
        margin-bottom:2rem;
    }

    #vs-list .vs-tabs .profile-avatar {
        width: 300px;
    }
    #vs-list .vs-tabs .profile-avatar .sub-info {
        margin: 0px -50px 25px -50px;
    }
    #vs-list .group-header h3{
        font-size:2.2rem;
    }
    #vs-list .group-header {
        margin-bottom: 2rem;
    }
    #vs-list #chat .all-messages .current-message .message-options{
        height:190px;
    }

    .daily-posts .filter-panel{
        top:46px;
        padding:18px;
    }

    #material h2{
        font-size: 3.5rem;
        line-height: 4.5rem;
    }
    .tiny-content p {
        margin: 15px 0;
    }
    .tiny-content h1, 
    .tiny-content h2, 
    .tiny-content h3, 
    .tiny-content h4{
        margin: 0px 0 15px 0;
    }
    .whole-container {
        padding: 75px 30px 30px 280px;
        transition: 0.5s all;
    }
    .card {
        margin-bottom: 3rem;
    }
    .card .card-header, .modal-header {
        font-size: 16px;
        line-height: 17px;
        padding: 0px 48px;
        height: 46px;
    }
    .card .card-header:before, 
    .card .card-header:after, 
    .modal-header:before, 
    .modal-header:after {
        top: 17px;
    }
    .card .card-header:before, 
    .modal-header:before {
        left: 17px;
    }
    .card .card-header:after, 
    .modal-header:after {
        right: 17px;
    }
    .card .card-body {
        padding: 18px;
    }
    .table td { 
        padding: 0.5rem;
    }
    .table td .badge {
        padding: 0.5rem 1rem;
        min-width: 80px;
    }
    select.form-control.thiner, 
    input.form-control.thiner {
        padding: 0.5rem 1rem;
        width: 94px;
    }
    .service{
        padding-right: 5px;
        padding-left: 5px;
    }
    .service .icon {
        width: 180px;
        height: 180px;
        margin: 0 auto -40px auto;
        border:6px solid #fff;
    }
    .card .card-header select, .card .card-header .form-control:focus, .card .card-header .form-control:active{
        width: 94px;
    }
    .mb-space {
        margin-bottom: 3rem;
    }
	.tiny-content img{
        max-width:100%;
        height:auto;
    }

    .btn-thiner {
        font-size: 1.2rem !important;
    }
    .ReactModal__Content .close {
        margin: 3px 0 0 auto !important;
    }

    .avatar-image img {
        border: 6px solid #fff;
    }

    .row.m-40{
        margin-right: -30px;
        margin-left: -30px;
    }
}