@media (max-width: 1200px) {

    #ls-gift .tabs{
        display:flex;
    }
    #ls-gift h2{
        display:none;
    }
    #ls-gift h2{
        display:none;
    }
    #vs-list .vs-content.info .tiny-content img{
        height:150px;
    }
    #vs-list .groups {
        max-height: 110px;
    }
    p{
        font-size:1.4rem;
    }
    
    #ls-gift .col-12.hidden{
        display:none;
    }

    #material .material-content{
        padding: 3rem 28rem 3rem 3rem;
    }
    #vs-list #chat{
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 120px;
        right: 270px;
    
    }
    #vs-list #chat.guide{
        top: 165px;
    }
    #vs-list #chat.is-group{
        top: 130px;
    }
    #vs-list .vs-content{
        padding: 2rem 27rem 2rem 2rem;
    }
    #vs-list .vs-tabs .profile-avatar {
        width: 250px;
    }
    #vs-list .vs-tabs .nav-link {
        padding: 0.5rem 1rem;
    }
    #vs-list .vs-tabs .nav-tabs  {
        margin-bottom: 1rem;
    }
    #vs-list #chat .write-message {
        margin-top: 10px;
    }
    #vs-list #chat .write-message .message-container .message,
    #vs-list #chat .write-message,
    #vs-list #chat .write-message .message {
        min-height: 40px;
    }
    #vs-list #chat .write-message .message {
        max-height: 115px;
    }
    #vs-list #chat .write-message .emotikons .emotikons-container,
    #vs-list #chat .write-message .add-images-container,
    #vs-list #chat .write-message .send .send-container,
    #vs-list #chat .all-messages .current-message .image.right,
    #vs-list #chat .all-messages .current-message .image {
        height: 40px;
        width: 40px;
    }
    #vs-list #chat .write-message .add-images-container img{
        height: 25px;
        top: 8px;
        left: 8px;
    }
    #vs-list #chat .write-message .message-container,
    #vs-list #chat .write-message .placeholder {
        height: 40px;
    }
    #vs-list #chat .write-message .emotikons,
    #vs-list #chat .write-message .send,
    #vs-list #chat .write-message .add-images {
        width: 40px;
    }
    #vs-list #chat .add-images-modal .images-list {
        height: 100px;
    }
    #vs-list #chat .add-images-modal .images-list .img:hover .close-img{
        display: none;
    }
    #vs-list #chat .all-messages .current-message-content {
        padding: 7px;
    }
    #vs-list #chat .add-images-modal .controls {
        min-height: 80px;
        padding: 1rem 2rem 2rem 2rem;
    }
    #vs-list .group-header h3{
        font-size:2rem;
    }
    #vs-list .group-header {
        margin-bottom: 1.5rem;
    }
	
	#material .card.side,
    #vs-list .card.side {
        width: 250px;
    }	

    #material .material-content iframe,
    #vs-list .vs-content iframe{
        height: calc((100vw - 660px) * 9 / 16);
    }

    .ReactModal__Content .event iframe{
        height: calc((100vw - 80px) * 9 / 16);
    }

    #material .card.side h3,
    #vs-list .card.side h3 {
        font-size: 1.6rem;
    }
    #vs-list .vs-list .vs {
        padding: 5px;;
    }
    #vs-list .vs-list .vs .info p.main-name {
        font-size: 1.3rem;
    }
    #vs-list .vs-content .daily-posts .card{
        margin-bottom:2rem;
    }
    #vs-list .vs-tabs .btn-item {
        margin-left: 0;
        width: 100%;
        margin-top: 1rem;
    }
    #vs-list .vs-tabs .btn-item a{
        max-width:100%;
        width:100%;
    }
    #material h2{
        font-size: 3rem;
        line-height: 4rem;
        margin-top:1rem;
    }

    #no-access{
        width:600px;
    }

    .calendar-events .card-body .title {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }

    .tiny-content h1{
        font-size:2.4rem;
    }
    .tiny-content h2{
        font-size:2.2rem;
    }
    .tiny-content h3{
        font-size:2rem;
    }
    .tiny-content h4{
        font-size:1.8rem;
    }
    .tiny-content p {
        margin: 15px 0;
        font-size: 1.4rem;
    }
    textarea.form-control, 
    input.form-control, 
    select.form-control, 
    select.form-control:focus, 
    select.form-control:active, 
    input.form-control:focus, 
    input.form-control:active, 
    .input-group-text, 
    .form-control:disabled, 
    .form-control[readonly] {
        padding: 1rem 1rem;
        height: 36px;
    }
    select.form-control.thiner, 
    input.form-control.thiner,
    select.form-control.thiner:active, 
    input.form-control.thiner:active{
        height:auto;
        padding: 0.5rem 1rem;
        width: 94px;
    }
    .modal-lg{
        margin: 0 20px;
    }
    .daily-posts .nav-tabs .nav-link {
        padding: 0.75rem 0.75rem;
    }

    .profile-avatar .sub-info {
        margin: 0px -25px 50px -25px;
    }

    .modal-dialog {
        margin: 2rem auto;
        width: 95%;
    }

    .row.m-40{
        margin-right: -20px;
        margin-left: -20px;
    }

    .service .card {
        flex: 1 1;
        margin-bottom: 4rem;
    }
}