@media (max-width: 575px) {

    body{
        background-size: 1000px 1000px;
    }
    
    #login .card {
        width: 90%;
    }
    #login .logo img{
       height:110px;
       margin-top:50px;
       margin-bottom:50px;
    }
    #ls-gift .tabs {
        gap: 0rem;
    }
    #ls-gift .tabs .tab-item {
        margin: 0 0.5rem;
    }
    #ls-gift .tabs .tab-item span {
        font-size: 1.6rem;
    }
    #questions{
        width:90%;
    }

    #questions .logo{
        height: 110px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    #header {
        background-position: -3090px center;
    }
    #header ul li.membership {
        font-size: 1.2rem;
    }
    #header ul li.membership span{
        display:block;
    }
    #header ul li.logo img {
        margin-right: 0px;
    }
    #header .sideBar {
        width: 100%;
        transform: translateX(-110%);
    }
    #header ul li a, 
    #header ul li button,
    #header ul li.theme button {
        padding: 10px 5px;
    }
    #header ul li.menu-icon button {
        padding: 10px;
    }
    #header ul li.mobile-membership ul li {
        display: block;
        border:0px none;
        text-align:center;
    }
    #header ul li.mobile-membership ul li button{
        margin:0 auto;
    }
    #header .notifications-list{
        right:-93px;
    }
    #no-membership button {
        padding: 15px !important;
        font-size: 1.2rem !important;
    }
    .daily-posts .filter-panel{
        top:36px;
    }
    .table td .badge {
       
        font-size: 1rem;
        line-height: 1.2rem;
        padding: 0.5rem 1rem;
        min-width: 75px;
    }
    .table td {
        font-size: 1.2rem;
        padding: 0.5rem;
    }
    .table th{
        font-size: 1.2rem;
    }
    .modal-body .table td {
        font-size: 1.2rem;
    }
    .card .card-header:before, 
    .card .card-header:after, 
    .modal-header:before, 
    .modal-header:after {
        width: 10px;
        height: 10px;
        top:13px;
    }
    .card .card-header:before, 
    .modal-header:before {
        left: 13px;
    }
    .card .card-header:after, 
    .modal-header:after {
        right: 13px;
    }
    .card .card-header, .modal-header {
        padding: 0px 33px;
        font-size: 14px;
        height: 36px;
    }
    .card .card-header select{
        flex: 0 0 50%;
    }
    .card .select-container{
        width:100%;
        margin-top: 5px;
    }
    .card.profits .card-header{
        height:7rem;
    }
    .card .card-header.flex-column{
        height:7rem;
    }
    .card .card-header select {
        flex: 0 0 50%;
    }
    .header-avatar{
        margin-left:0px;
    }
    .paid-off{
        flex-direction: column;
    }
    .paid-off div{
        margin:0;
        width: 100%;
        text-align: center;
    }
    .paid-off .minimal-amount{
        margin-bottom:1rem;
    }

    .payu-desc *{
        font-size:1.2rem;
    }
    .modal-body .payu-desc p {
        font-size: 1.2rem;
    }
    #material .mobile-lessons,
    #vs-list .mobile-lessons{	
        font-size:1.4rem;
    }

    #header ul li.user span{
        display:none;
    }
    #header ul li.user img{
        margin:0px;
    }
    #header .btn-primary {
        margin: 1rem 0rem 1rem 0rem;
        padding: 0.75rem !important;
    }
    #header ul li.user a, 
    #header ul li.user button{
        padding: 8px 5px;
    }
    #material .card.side{
        transform: translateX(100%);
        width:100%;
        border-left:0px none;
    }    
    #no-access{
        width:calc(100% - 30px);
    }

    #layout .waiting-info{
        flex-direction: column;
        text-align: center;
    }
    #vs-list #chat .all-messages .current-message-content,
    #vs-list #chat .write-message .message-container .message,
    #vs-list #chat .all-messages .current-message-content *{
        font-size:1.2rem;
    }
    #vs-list .group-header h3 {
        font-size: 1.4rem;
    }
    #vs-list .vs-tabs .nav-tabs li.name {
        font-size: 1.4rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width:35%;
    }
    #vs-list .vs-tabs .nav-tabs li a img {
        height: 20px;
    }
    #vs-list .vs-tabs .nav-tabs img.back {
        height: 25px;
    }
    #vs-list #chat .write-message .message-container .message, 
    #vs-list #chat .write-message, 
    #vs-list #chat .write-message .message {
        min-height: 30px;
    }
    #vs-list #chat .write-message .emotikons .emotikons-container, 
    #vs-list #chat .write-message .add-images-container, 
    #vs-list #chat .write-message .send .send-container, 
    #vs-list #chat .all-messages .current-message .image.right, 
    #vs-list #chat .all-messages .current-message .image {
        height: 30px;
        width: 30px;
    }
    #vs-list #chat .write-message .add-images-container img{
        height: 19px;
        top: 6px;
        left: 6px;
    }
    #vs-list #chat .write-message .emotikons img, 
    #vs-list #chat .write-message .send img{
        height: 18px;
    }
    #vs-list #chat .write-message .emotikons, 
    #vs-list #chat .write-message .send, 
    #vs-list #chat .write-message .add-images {
        width: 30px;
    }
    #vs-list #chat .write-message .placeholder {
        height: 30px;
    }
    #vs-list #chat .add-images-modal .controls {
        min-height: 61px;
    }
    #vs-list #chat .all-messages .current-message .message-options {
        height: 145px;
    }
    #notification{
        width:auto;
        left:20px;
    }
    #room #room-footer button.reservations{
        padding:5px;
        width:auto;
    }
    #room #room-footer button.users{
        padding:5px 8px;
        width:auto;
    }
    .visit-time{
        padding: 0 10px;
    }
    .profile-avatar {
        width: 100%;
    }
    .profile-avatar .name-container p.main-name {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
    .profile-avatar .name-container p.sub-name {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    .profile-avatar .name-container {
        width: 90%;
        margin: -75px auto 0px auto;
    }

    .profile-avatar .sub-info,
    #vs-list .vs-tabs .profile-avatar .sub-info {
        margin: 0px auto 25px auto;
        font-size: 1.2rem;
    }
    .profile-avatar.fluid .sub-info {
        width: 100%;
        margin: 0px 0px 25px 0px;
    }

    .ReactModal__Content .event iframe{
        margin:0px;
        width:100%;
        border:0px none;
        height: calc((100vw - 30px) * 9 / 16);
    }
    .ReactModal__Content .close {
        margin: 0px 0 0 auto !important;
        height:20px;
    }

    .emoji-mart{
        right: -60px !important;
        z-index: 100 !important;
        top:-360px !important;
    }
    .modal-lg {
        margin:0.5rem;
    }

    .reg-title{
        font-size:0.875rem;
    }

    .btn-thiner {
        padding: 0.5rem 1.2rem !important;
    }
    .modal-dialog {
        margin: 1rem auto;
    }

}
	