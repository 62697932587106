@media (max-width: 767px) {

    #header .btn-primary {
        margin: 0.6rem 1rem 0.6rem 0.5rem;
    }
    #ls-gift .col-12 {
        padding: 0 1.5rem;
    }
    #ls-gift .row {
        margin: 0 -1.5rem;
    }
    #ls-gift .tabs {
        margin: 0 0 1.5rem 0;
    }
    .whole-container {
        padding: 60px 15px 15px 15px;
    }
    .card {
        margin-bottom: 2rem;
    }

    #ls-gift .tabs .tab-item span {
        font-size: 2rem;
    }
    #vs-list #chat .add-images-modal .images-list {
        height: 85px;
        padding: 1.5rem 0;
        margin: 0 1.5rem;
    }
    #vs-list #chat .add-images-modal .images-list img:not(.close){
        border-radius:0.5rem;
    }
    #vs-list #chat .add-images-modal .close-images {
        width: 25px;
        height: 25px;
    }
    #vs-list #chat.guide {
        top: 155px;
    }

    .daily-posts .filter-panel{
        padding:1rem;
    }
    #questions{
        width:500px;
    }
    .table tr img {
        height: 22px;
    }
    .mb-space {
        margin-bottom: 2rem;
    }
	
	.payu-desc *{
        font-size:1.4rem;
    }
    .modal-body .payu-desc {
        margin: 0px;
    }
    .modal-body .payu-form {
        margin: 0px;
    }
    .modal-body .payu-form .checkboxes *{
        font-size: 1.2rem;
    }

    label {
        font-size: 1.2rem;
    }
    .attention-icon{
        margin-bottom:10px;
    }

    #material .material-content,
    #vs-list .vs-content{
        flex:1;
        padding: 1.5rem;
    }

    #material .material-content iframe,
    #vs-list .vs-content iframe{
        height: calc((100vw - 80px) * 9 / 16);
    }

    #material .card.side{
        transform: translateX(250px);
        top:117px;
        border:0px none;
        border-left:1px solid #ddd;
        transition: all 0.5s;
    }
    #vs-list .card.side{
        transform: translateX(0px);
        top:46px;
        width:100%;
        transition: all 0.5s;
    }
    #vs-list .card.side.closed{
        transform: translateX(100%);
        top:46px;
        width:100%;
    }
    #vs-list #chat{
        left: 15px;
        right: 15px;
        bottom:15px;
    }
    #vs-list #chat.is-group {
        top: 125px;
    }

    #material .card.side.open,
    #vs-list .card.side.open{
        transform: translateX(0px);
    }
    #material h2{
        font-size:2.2rem;
        line-height: 2.8rem;
    }
    #material .card.side .header h3{
        font-size:1.4rem;
    }
    #material .card .card-header{
        display:none;
    }

    #no-access{
        width:500px;
    }
    
    .calendar-events .card-body .title {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .calendar-events .card-body .date{
        font-size:1.6rem;
    }
    .calendar-events .card-body .date .year {
        font-size: 1.2rem;
    }
    .calendar-events .year-separator .year{
        font-size: 1.6rem;
        padding: 0.5rem 1rem;
        line-height: 1.6rem;
    }

    .tiny-content h1{
        font-size:2.2rem;
    }
    .tiny-content h2{
        font-size:2rem;
    }
    .tiny-content h3{
        font-size:1.8rem;
    }
    .tiny-content h4{
        font-size:1.6rem;
    }

    .tiny-content p {
        margin: 15px 0;
    }
    .tiny-content h1,
    .tiny-content h2,
    .tiny-content h3,
    .tiny-content h4{
        margin-bottom: 15px;
    }

    #material .mobile-lessons,
    #vs-list .mobile-lessons{
        display:block;
        font-size: 1.6rem;
        margin: 1.5rem 1.5rem 0 1.5rem;
        border-radius: 1rem;
        box-shadow: 0 0 15px rgba(0,0,0,0.15);
        text-transform: uppercase;
    }

    #vs-list .vs-tabs .nav-tabs li:first-child {
        margin-right:1rem;
        display: flex;
        align-items: center;
    }

    #vs-list .vs-tabs .nav-tabs img.back {
        display:inline-block;
    }
    #vs-list .vs-tabs .nav-tabs li.name {
        display:flex;
    }
    #vs-list .vs-tabs .nav-tabs li  img {
        margin-right:0rem;
    }

    #vs-list .group-header{
        text-align: right;
    }
    #vs-list .group-header h3{
        font-size:1.6rem;
    }
    #vs-list .group-header img{
        display:block;
    }
    #vs-list .vs-tabs .nav-link{
        padding:0.5rem;
    }
    #vs-list .vs-tabs .nav-link span{
        display:none;
    }
    #vs-list .vs-tabs .nav-tabs li a img{
        margin-right:0;
    }
    #vs-list .vs-tabs .daily-posts li:first-child {
        display: list-item;
        margin-right:0;
    }
    #vs-list .vs-content.info .tiny-content {
        display:none;
    }
    #vs-list .vs-content .daily-posts .card,
    .daily-posts .card {
        margin-bottom: 1.5rem;
    }
    #vs-list #chat .add-images-modal .controls {
        min-height: 71px;
        padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    }
    #vs-list #chat .all-messages .current-message .message-options {
        height: 155px;
    }
    #vs-list #chat .all-messages .current-message .message-options .option img {
        height: 18px;
        margin-right: 0.5rem;
    }
    #vs-list #chat .all-messages .current-message .message-options .option:first-child {
        padding: 0 0 0.6rem 0;
    }
    #vs-list #chat .all-messages .current-message .message-options .option {
        padding: 0.6rem 0;
        font-size: 1.3rem;
        line-height: 1.3rem;  
    }
    #vs-list #chat .all-messages .current-message .message-options .option .alert{
        padding:0.5rem 1.25rem;
    }
    .daily-posts .nav-tabs .nav-link{
        padding:0.75rem !important;
        box-shadow: none !important;
    }
    .daily-posts .nav-link{
        box-shadow: none;
    }
    .daily-posts .nav-link.active{
        border:0px none;
        border-bottom: 1px solid #ddd !important;
    }
    .daily-posts .mobile-tabs{
        display:block;
        margin-bottom:15px;
    }
    .service .icon {
        width: 150px;
        height: 150px;
        margin: 0 auto -30px auto;
    }   

    .daily-posts .nav-tabs .nav-link {
        
        border-radius: 0rem;
        box-shadow: none;
        border-bottom:1px solid #ddd;
    }

    .daily-posts .nav-tabs .nav-item:nth-child(1) .nav-link{
        border-radius:1rem 1rem 0 0 !important
    }
    .daily-posts .nav-tabs .nav-item:last-child .nav-link{
        border-radius:0 0 1rem 1rem !important
    }
    .daily-posts .nav-tabs{
        display:none;
        position:absolute;
        left:0px;
        top:43px;
        z-index: 1;
        width:100%;
        box-shadow: 0 10px 15px rgba(0,0,0,0.1);
        border-radius: 1rem;
    }
    .daily-posts .nav-tabs.open{
        display:block;
    }
    .daily-posts .nav-tabs li{
        margin-bottom:0px !important;
    }
    .daily-posts .nav-tabs li a{
        margin:0px;
    }

    .card .card-body{
        padding:1rem;
    }
    .modal-body {
        padding: 2rem;
    }
    .modal-body .video-desc{
        font-size: 1.2rem;
    }
    .alert {
        font-size: 1.2rem;
    }

    .btn {
        font-size: 1.2rem !important;
        line-height: 1.2rem !important;
        padding: 1.2rem !important;
    }
    .btn-thiner {
        font-size: 1.1rem !important;
        padding: 1.2rem 1rem !important;
    }
    .room-icon{
        height:125px;
    }
    #layout .waiting-info img{
        height:150px;
    }
    
}
	